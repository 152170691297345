<template>
  <base-page
      :title="$t('companyApiManage.title')"
      @functionMethod="functionMethod"
      @search="search"
  >
    <table-list
        ref="formList"
        :url="'/' + $util.getLocalStorage('apiprefix') + $api.apiList"
        :columns="columns"
        :defaultParams="defaultParams"
        v-model="tableRow"
    >
      <!-- 创建时间 -->
      <template v-slot:createTime="{ row }">
        {{ $util.format(row.createTime) }}
      </template>
      <!-- 规则 -->
      <template v-slot:ruleType="{ row }">
        {{ $t('apiRuleTypeEnum.' + row.ruleType) }}
      </template>
      <template v-slot:headerJson="{ row }">
        <div v-for="(item,index) in JSON.parse(row.headerJson)" :key="index">
          {{item.key}} => {{item.value}}
        </div>
      </template>
      <!-- 操作 -->
      <template v-slot:option="{ row }">
        <el-button v-if="$page.showBtn('PRODUCT_SERVICE_MANAGE_UPDATE')" type="text" @click="editServer(row)">
          {{ $t('functionEnum.PRODUCT_SERVICE_EDIT') }}
        </el-button>
      </template>
    </table-list>
    <detail v-if="showDetail"
            :tableRow="tableRow"
            @closePage="closePage"
    />
  </base-page>
</template>

<script>
import Detail from './detail'
export default {
  components: {
    Detail
  },

  data() {
    return {
      formData: {},
      defaultParams: {
        scope: 'COMPANY',
        companyUid: this.$store.getters.company.uid,
      },
      tableRow: null,
      showDetail: false,
      columns: [
        {
          label: this.$t('companyApiManage.name'),
          prop: 'name',
        },
        {
          label: this.$t('companyApiManage.ruleType'),
          prop: 'ruleType',
        },
        {
          label: this.$t('companyApiManage.url'),
          prop: 'url',
        },
        {
          label: this.$t('companyApiManage.method'),
          prop: 'netRequestType',
        },
        {
          label: this.$t('companyApiManage.header'),
          prop: 'headerJson',
        },
        {
          label: this.$t('companyApiManage.request'),
          prop: 'requestBody',
        },
        {
          label: this.$t('companyApiManage.response'),
          prop: 'responseBody',
        },
        {
          label: this.$t('base.createTime'),
          prop: 'createTime',
        },
      ],
    }
  },
  methods: {
    functionMethod(item) {
      console.log(item)
      const { method } = item
      if (method !== 'add' && !this.tableRow) {
        this.$element.showMsg(this.$t('base.selectTableRow'), 'warning')
        return
      }
      if (
          method === 'add' &&
          this.tableRow
      ) {
        this.$element.showMsg(this.$t('base.canAddFirstCate'), 'warning')
        return
      }
      switch (method) {
        case 'add':
        case 'edit':
        case 'view':
          this.showDetail = true
          break
        case 'del':
          this.deleteMethod()
          break
        default: // do something
      }
    },
    closePage(method) {
      this.showDetail = false
      if (method && method !== 'cancel') {
        this.search()
      }
    },
    search(isActiveQuery) {
      const formData = this.$util.copyData(this.formData)
      this.$refs.formList.searchList(formData, isActiveQuery)
    },
    deleteMethod() {
      this.$element
          .showConfirm({
            content: this.$t('base.deleteConfirm'),
          })
          .then(() => {
            this.$ajax({
              url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.apiDel,
              data: {id:this.tableRow.id},
            }).then(() => {
              // lang:删除成功
              this.$element.showMsg(this.$t('base.deleteSuccess'), 'success')
              this.search()
            })
          })
    },
  }
}
</script>

<style scoped>

</style>
